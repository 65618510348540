import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";
import { ProjectType } from "../../types";
import ProjectIcon from "./project-icon";
import ProjectStatus from "./project-status";
import ProjectTags from "./project-tags";

const Project = (props) => {
  const {
    name,
    image,
    url,
    description,
    status,
    tags,
    icon,
    source_code,
    project_icon,
  } = props;

  return (
    <div className="border-t-4 border-line relative flex flex-wrap bg-back-light p-4 lg:p-8 bg-no-repeat text-sm mb-6">
      {image && (
        <div className="w-full pb-4 lg:w-2/5 lg:pr-8 lg:pb-0">
          <GatsbyImage
            image={image.childImageSharp.gatsbyImageData}
            alt={name}
          />
        </div>
      )}
      <div className="lg:flex-1">
        <div className="flex justify-start items-center gap-2">
          <div
            style={{ border: "1px solid #e1e1e1" }}
            className="aspect-square shadow-sm p-1 rounded-sm w-16 h-16 flex flex-shrink-0 justify-center items-center overflow-hidden"
          >
            <img className="w-full h-full object-cover" src={project_icon} />
          </div>
          <div className="w-full">
            <h4 className="font-bold">{name}</h4>
            {url && (
              <a
                className="text-front underline break-all hover:opacity-75 transition-opacity duration-150"
                href={url}
                rel="noreferrer noopener"
                target="_blank"
              >
                Project Link
              </a>
            )}
            <br />

            {/* Go to ./projects.js line number 6 to manage source code order */}
            {!!source_code && (
              <a
                className="text-front italic font-bold underline break-all hover:opacity-75 transition-opacity duration-150"
                href={source_code}
                rel="noreferrer noopener"
                target="_blank"
              >
                Source Code
              </a>
            )}
          </div>
        </div>
        <p className="w-full py-4 whitespace-pre-line">{description}</p>
        <ul className="pr-2">
          {status && <ProjectStatus status={status} />}
          {tags && <ProjectTags tags={tags} />}
        </ul>

        {icon && <ProjectIcon icon={icon} />}
      </div>
    </div>
  );
};

Project.propTypes = ProjectType;

export default Project;
