import React from "react";
import Location from "./location";
import ProfileImage from "./profile-image";
import { arrayOf, shape, ProfileType, SocialType } from "../../types";
import SocialLinks from "../social-links/social-links";
import { FaPaperclip } from "react-icons/fa";

const Sidebar = ({ profile, social }) => {
  return (
    <aside className="w-full lg:w-1/3 lg:border-r border-line lg:px-6 xl:px-12 ">
      <div className="flex flex-col h-full ">
        <div id="sidecard">
          <h2 className="font-header font-light text-front text-2xl leading-none mb-4">
            {profile.profession}
          </h2>
          <h1 className="font-header font-black text-front text-5xl leading-none mb-6">
            {profile.name.split(" ")[0]}&nbsp;
            <span className="font-light">{profile.name.split(" ")[1]}</span>
          </h1>
          {profile.image && (
            <ProfileImage image={profile.image} name={profile.name} />
          )}
          <br />
          {profile.location && (
            <Location
              location={profile.location}
              relocation={profile.relocation}
            />
          )}
          <br />

          <>
            <a
              href="https://drive.google.com/file/d/16-Dr6-yDbTbdzNOQyB7ZJL_rjU9txUW3/view"
              target="_blank"
              className="inline-flex items-center bg-front mt-6 w-auto"
            >
              <span className="text-lead border-r-2 border-back px-3">
                <FaPaperclip className="h-4 w-4" />
              </span>
              <span className="text-back font-header font-bold py-1 px-3 text-lg">
                View Resume
              </span>
            </a>
          </>
          <div className="pt-8 pb-12 lg:py-0 lg:mt-8">
            <h5 className="font-header font-semibold text-front text-sm uppercase mb-3">
              Connect
            </h5>
            <SocialLinks social={social} />
          </div>
        </div>
      </div>
    </aside>
  );
};

Sidebar.propTypes = {
  profile: shape(ProfileType),
  social: arrayOf(shape(SocialType)),
};

export default Sidebar;
