import React from "react";

const ArticleTags = ({ tags, filterTag }) => {
  return (
    <>
      {tags.map((tag) => (
        <li
          className="inline-block px-3 py-1 mr-1 mt-1 font-medium text-xs rounded-lg border border-back"
          style={{
            backgroundColor: filterTag === tag ? "var(--primary-color)" : "",
          }}
          key={tag}
        >
          {tag}
        </li>
      ))}
    </>
  );
};

export default ArticleTags;
