import PropTypes from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";

const Seo = (props) => {
  const {
    description = "Innovative professional with a 2 year experience in developing (full stack), backend / frontend, is accredited by peers, subordinates, senior leaders as well as clients for tenacity and the ability to gracefully handle the dynamics of a rapidly changing corporate environment.",
    meta = [],
    title = "Shubham Waje",
  } = props;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
      title={title}
      link={[
        {
          rel: "icon",
          type: "image/svg+xml",
          href: "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/sw_fav.png",
        },
      ]}
      meta={[
        {
          name: `description`,
          content: description,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: description,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: `https://wajeshubham.in`,
        },
        {
          property: `og:image`,
          content: `https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/cover_sw.png`,
        },
        {
          property: `twitter:image`,
          content: `https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/cover_sw.png`,
        },
        {
          property: `twitter:domain`,
          content: `https://wajeshubham.in`,
        },
        {
          property: `twitter:image:src`,
          content: `https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/cover_sw.png`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: description,
        },
      ].concat(meta)}
    />
  );
};

Seo.propTypes = {
  description: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
};

export default Seo;
