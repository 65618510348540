import React from "react";
import ArticleStatus from "./article-status";
import ArticleTags from "./article-tag";

const Article = ({
  name,
  description,
  url,
  image,
  status,
  tags,
  filterTag,
}) => {
  return (
    <div className="border-t-4 border-line relative flex flex-wrap bg-back-light p-4 lg:p-8 bg-no-repeat text-sm mb-6">
      {image && (
        <div className="w-full lg:w-3/6 lg:pr-8 lg:pb-0">
          <img className="border-lead p-1 border-2" src={image} alt={name} />
        </div>
      )}
      <div className="lg:flex-1">
        <h4 className="font-bold mt-2 lg:mt-0">{name}</h4>
        {url && (
          <a
            className="text-front underline break-all hover:opacity-75 transition-opacity duration-150"
            href={url}
            rel="noreferrer noopener"
            target="_blank"
          >
            Read the article
          </a>
        )}
        <br />

        <p className="w-full my-3 text-line-2">{description}</p>
        <ul className="pr-2">
          {status && <ArticleStatus status={status} />}
          {tags && <ArticleTags filterTag={filterTag} tags={tags} />}
        </ul>
      </div>
    </div>
  );
};

export default Article;
