import React from "react";
import SocialIcon from "./social-icon";
import { SocialType, arrayOf, shape } from "../../types";

const SocialLinks = ({ social }) => {
  return (
    <div className="pl-1 ">
      {social.map(({ name, url }, i) => {
        const colorsClass =
          i % 2 === 0 ? "bg-front text-back-light" : "bg-back-light text-front";
        return (
          <a
            aria-label={name}
            className={`social-icon hover:shadow-xl inline-flex w-12 h-12 justify-center items-center rounded-full -ml-2 ${colorsClass}`}
            href={url}
            key={name}
            rel="noopener noreferrer"
            target="_blank"
            style={{ border: "0.5px solid #999999" }}
          >
            <SocialIcon name={name} className="w-6 h-6" />
          </a>
        );
      })}
    </div>
  );
};

SocialLinks.propTypes = {
  social: arrayOf(shape(SocialType)),
};

export default SocialLinks;
