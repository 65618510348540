import React from "react";
import Project from "./project";
import { arrayOf, shape, ProjectType } from "../../types";

const Projects = ({ projects }) => {
  const source_codes = [
    "https://github.com/hiteshchoudhary/apihub",
    "https://github.com/wajeshubham/snippng",
    "",
    "https://github.com/wajeshubham/path-finder",
    "https://github.com/wajeshubham/react-native-uber",
    "https://github.com/wajeshubham/PWA-Weather-app",
  ];

  const images = [
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/freeapiv1.png",
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/snippng.png",
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/findcoder.webp",
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/maps.svg",
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/uber.png",
    "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/weather.webp",
  ];

  return (
    <>
      <h5
        className="font-header font-semibold text-front text-sm uppercase mb-3"
        id="projects"
      >
        Projects
      </h5>
      {projects.map((project, i) => (
        <Project
          key={`${project.name}_${i}`}
          {...project}
          source_code={source_codes[i]}
          project_icon={images[i]}
        />
      ))}
      <small className="italic underline text-gray-700 font-semibold w-full inline-flex justify-end items-end gap-1">
        View more of my work on my{" "}
        <a
          className="text-blue-500"
          href="https://github.com/wajeshubham"
          target="_blank"
        >
          github
        </a>{" "}
        profile.
      </small>
    </>
  );
};

Projects.propTypes = {
  projects: arrayOf(shape(ProjectType)),
};

export default Projects;
