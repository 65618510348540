import React from "react";
import "../header/header.css";

const DropdownMenu = React.forwardRef(
  ({ openMenu, toggleMenu = (f) => f }, ref) => {
    return (
      <div
        ref={ref}
        className={"menu shadow-md"}
        id="side_cart"
        style={{
          // transform: openMenu ? "translate(0%, 0%)" : "translate(200%, 0%)",
          // webkitTransform: openMenu ? "translate(0%, 0%)" : "translate(200%, 0%)",
          opacity: openMenu ? 1 : 0,
          backgroundColor: "white",
          width: "260px",
        }}
      >
        <ul className="d__ul">
          <li
            aria-hidden="true"
            onClick={() => {
              toggleMenu();
              document.getElementById("projects").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            {" "}
            <span
              style={{
                fontSize: "15px",
                marginTop: "0",
                textTransform: "uppercase",
                fontWeight: "300",
              }}
            >
              Projects
            </span>
          </li>
          <li
            aria-hidden="true"
            onClick={() => {
              toggleMenu();
              document.getElementById("articles").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            {" "}
            <span
              style={{
                fontSize: "15px",
                marginTop: "0",
                textTransform: "uppercase",
                fontWeight: "300",
              }}
            >
              Articles
            </span>
          </li>
          <li
            aria-hidden="true"
            onClick={() => {
              toggleMenu();
              document.getElementById("work").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            <span
              style={{
                fontSize: "15px",
                marginTop: "2px",
                textTransform: "uppercase",
                fontWeight: "300",
              }}
            >
              Work History
            </span>
          </li>
          <li
            aria-hidden="true"
            onClick={() => {
              toggleMenu();
              document.getElementById("contact").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            {" "}
            <span
              style={{
                fontSize: "15px",
                marginTop: "0",
                textTransform: "uppercase",
                fontWeight: "300",
              }}
            >
              Contact Me
            </span>
          </li>
          <li
            aria-hidden="true"
            onClick={() => {
              toggleMenu();
              document.getElementById("contact").scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            {" "}
            <span
              style={{
                fontSize: "15px",
                marginTop: "0",
                textTransform: "uppercase",
                fontWeight: "300",
              }}
            >
              <a href="https://blog.wajeshubham.in" target="_blank">
                <span>Read blogs</span>
              </a>
            </span>
          </li>
        </ul>
      </div>
    );
  }
);

export default DropdownMenu;
