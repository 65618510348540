import React, { useEffect, useRef, useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { useOnClickOutside } from "../../hooks";
import Article from "./article";

let ARTICLES = [
  {
    name: "Classes in TypeScript",
    description:
      "Looking back to our last article, we covered Functions in TypeScript which gave us an idea about how Functions work, are structured, and implemented in TypeScript. ",
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1674503520032/eefd1a6f-eea6-42d5-a8cd-1ae11c2467f3.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    url: "https://blog.wajeshubham.in/classes-in-typescript",
    tags: ["TypeScript", "Classes", "OOPs"],
  },
  {
    name: "PostgreSQL Hosting on EC2: Configuring Automated S3 Backups",
    description:
      "The primary reason for hosting your database on EC2 is cost savings. At the time of writing this article, the most affordable/smallest RDS deployment costs approximately $20-$40 per month (depending on the usage).",
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1691497720669/fffae9c1-961b-4a99-beec-00b46f8e2699.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    url: "https://blog.wajeshubham.in/postgresql-hosting-on-ec2-configuring-automated-s3-backups",
    tags: ["PostgreSQL", "S3", "AWS", "Deployment", "Cloud", "Production"],
  },
  {
    name: "How to integrate Razorpay payment gateway with Django REST framework and React.Js",
    description:
      "In this article, we are going to see how to integrate Razorpay with Django RF as backend and React.Js as frontend. We will also be seeing how to set up environment variables for our Razorpay API keys. About Razorpay Razorpay is an online payment solution in India allowing businesses to accept, process, and disburse payments with its product suite.",
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1611723504594/5A68Ts9OQ.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    url: "https://blog.learncodeonline.in/how-to-integrate-razorpay-payment-gateway-with-django-rest-framework-and-reactjs",
    tags: [
      "Python",
      "Javascript",
      "ReactJs",
      "Django",
      "Razorpay",
      "Payment gateway",
      "REST API",
      "Backend",
    ],
  },
  {
    name: "Promises in JavaScript",
    description:
      "In this article, you will learn about JavaScript promises, what they are, and how to use them effectively. A promise is an object that may produce a single value in the future after some time. Either it will be a resolved value or a rejected value.",
    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1611651351283/ZwrCq_sxW.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",
    url: "https://blog.learncodeonline.in/promises-in-javascript",
    tags: ["Javascript", "Promises", "asynchronous JS", "ES6"],
  },
  {
    name: "Linked List in Python",
    description:
      "In this blog, we will discuss about how to implement Linked List in Python. We will also discuss its properties, advantages, and disadvantages. I chose Python as the primary language to implement the linked list data structure because of its readability and the ease in implementing data structures.",

    image:
      "https://cdn.hashnode.com/res/hashnode/image/upload/v1613149247014/YFxXqU_aM.png?w=1600&h=840&fit=crop&crop=entropy&auto=compress,format&format=webp",

    url: "https://blog.learncodeonline.in/linked-list-in-python",
    tags: ["Python", "Data structures", "linked list"],
  },
];

const Articles = () => {
  const [articlesCount, setArticlesCount] = useState(3);
  const [openFilterOption, setOpenFilterOption] = useState(false);
  const [filterOptions, setFilterOptions] = useState([]);
  const [filterTag, setFilterTag] = useState(null);
  const [articles, setArticles] = useState(ARTICLES);
  const ref = useRef(null);

  useOnClickOutside(ref, (e) => {
    setOpenFilterOption(false);
  });

  useEffect(() => {
    let tagList = [];
    ARTICLES.map((article) => {
      tagList = [...tagList, ...article.tags];
      return null;
    });
    setFilterOptions(
      [...new Set(tagList)].sort(function (a, b) {
        return a.localeCompare(b);
      })
    );
  }, []);

  useEffect(() => {
    if (!filterTag) return;
    let validArticles = ARTICLES.filter((article, i) =>
      article.tags.includes(filterTag)
    );
    setArticles(validArticles);
  }, [filterTag]);

  return (
    <>
      <h5
        className="font-header font-semibold text-front text-sm uppercase mb-3 mt-10"
        id="articles"
      >
        Articles
        <span className="z-40 mr-2 font-header font-semibold  text-front text-sm uppercase cursor-pointer float-right relative">
          {articles.length < ARTICLES.length && (
            <button
              className="mr-4 text-red-600 border-none font-semibold text-sm uppercase"
              onClick={(e) => {
                setOpenFilterOption(false);
                setFilterTag(null);
                setArticles(ARTICLES);
              }}
            >
              Clear filters{" "}
            </button>
          )}
          <button
            className="border-none font-semibold  text-front text-sm uppercase"
            onClick={() => {
              setOpenFilterOption(!openFilterOption);
            }}
          >
            Filter by{" "}
            <IoMdArrowDropdown className="m-0 inline-block absolute top-0.5" />
          </button>
          {openFilterOption && (
            <span
              ref={ref}
              id="dropdown-menu"
              className="w-48 max-w-sm cursor-pointer absolute block bg-white mt-3 right-0 shadow-md"
            >
              <ul className="flex flex-col justify-start max-h-36 overflow-y-auto">
                {filterOptions.map((option, i) => (
                  <li
                    key={option}
                    className="p-2 article-filter cursor-pointer"
                    onClick={() => {
                      setFilterTag(option);
                      setOpenFilterOption(false);
                    }}
                    aria-hidden="true"
                  >
                    {" "}
                    {option}{" "}
                  </li>
                ))}
              </ul>
            </span>
          )}
        </span>
      </h5>

      {articles.slice(0, articlesCount).map((article, i) => (
        <Article
          filterTag={filterTag}
          key={`${article.name}_${i}`}
          {...article}
        />
      ))}
      {articles.length > 3 &&
        (articlesCount >= articles.length ? (
          <a
            href="https://hashnode.com/@wajeshubham"
            target="_blank"
            className="font-header w-full text-center inline-block font-semibold px-6 leading-tight cursor-pointer outline-none bg-front text-back-light uppercase mr-4 py-2 transition-opacity duration-150"
          >
            View All Blogs
          </a>
        ) : (
          <button
            onClick={() => {
              setArticlesCount(articlesCount + 3);
            }}
            className="font-header w-full inline-block font-semibold px-6 leading-tight cursor-pointer outline-none bg-front text-back-light uppercase mr-4 py-2 transition-opacity duration-150"
          >
            Load more
          </button>
        ))}
    </>
  );
};

export default Articles;
