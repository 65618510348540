import { graphql } from "gatsby";
import React from "react";
import CustomFonts from "../components/custom-fonts/custom-fonts";
import Footer from "../components/footer/footer";
import Header from "../components/header/header";
import MainContent from "../components/main-content/main-content";
import Seo from "../components/seo/seo";
import Sidebar from "../components/sidebar/sidebar";
import StructuredData from "../components/structured-data/structured-data";
import "../styles/style.css";
import { ToastContainer, Bounce } from "material-react-toastify";
import "material-react-toastify/dist/ReactToastify.css";

const IndexPage = ({ data }) => {
  if (!data) {
    return null;
  }
  try {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();
        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  } catch (e) {}

  const { history, profile, projects, site, social } = data;

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={3000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        limit={1}
        transition={Bounce}
      />
      <div className="antialiased bg-back leading-normal font-text text-front">
        <Seo />
        <StructuredData profile={profile} social={social.nodes} />
        <CustomFonts />

        <Header initials={profile.initials} />

        <div className="md:max-w-screen-sm lg:max-w-screen-xl mx-auto px-4 flex flex-wrap pt-4 my-8">
          <Sidebar profile={profile} social={social.nodes} />

          <MainContent
            history={history.nodes}
            profile={profile}
            projects={projects.nodes}
            formspreeEndpoint={site.siteMetadata.formspreeEndpoint}
          />
        </div>

        <Footer name={profile.name} showThemeLogo={false} />
      </div>
    </>
  );
};

export default IndexPage;

export const query = graphql`
  query {
    site {
      siteMetadata {
        showThemeLogo
        formspreeEndpoint
      }
    }
    profile: profileYaml {
      ...ProfileFragment
    }
    social: allSocialYaml(filter: { url: { ne: null } }) {
      nodes {
        ...SocialFragment
      }
    }
    history: allWorkHistoryYaml {
      nodes {
        ...WorkHistoryFragment
      }
    }
    projects: allProjectsYaml {
      nodes {
        ...ProjectFragment
      }
    }
  }
`;
