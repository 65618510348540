import { GatsbyImage } from "gatsby-plugin-image";
import React from "react";

let _certificates = [
  {
    title: "AWS Certified Cloud Practitioner",
    issuedBy: "Amazon Web Services",
    issueDate: "13th Nov, 2022",
    expiryDate: "13th Nov, 2025",
    url: "https://www.credly.com/earner/earned/badge/8d36a1d1-9cf2-4bf0-9e78-c4251c533b60",
    image: "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/CCP.png",
  },
  {
    title: "Docker & Kubernetes: The Practical Guide",
    issuedBy: "Udemy",
    issueDate: "24st Nov 2022",
    expiryDate: "Doesn't expire",
    url: "https://www.udemy.com/certificate/UC-b8a20f98-225c-405f-b175-e9733cf6eea8/",
    image:
      "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/docker.jpg",
  },
  {
    title: "TypeScript",
    issuedBy: "Udemy",
    issueDate: "23rd Jan 2022",
    expiryDate: "Doesn't expire",
    url: "https://www.udemy.com/certificate/UC-edc3a51d-b496-4183-bb5e-525b90a4f318/",
    image: "https://wajeshubham-portfolio.s3.ap-south-1.amazonaws.com/ts.jpg",
  },
];

const certificates = () => {
  return (
    <>
      <h5
        className="font-header font-semibold text-front text-sm uppercase my-3"
        id="projects"
      >
        Certificates
      </h5>
      {_certificates.map((certificate) => {
        const { image, expiryDate, issueDate, issuedBy, title, url } =
          certificate;
        return (
          <div className="border-t-4 border-line relative flex flex-wrap bg-back-light p-4 lg:p-8 bg-no-repeat text-sm mb-6">
            {image && (
              <div className="w-full pb-4 lg:w-2/5 lg:pr-8 lg:pb-0">
                <img
                  src={image}
                  alt={title}
                  className="border border-1 border-gray-300"
                />
              </div>
            )}
            <div className="lg:flex-1">
              <h4 className="font-bold">{title}</h4>
              {url && (
                <a
                  className="text-front underline break-all hover:opacity-75 transition-opacity duration-150"
                  href={url}
                  rel="noreferrer noopener"
                  target="_blank"
                >
                  Credential URL
                </a>
              )}

              <p className="w-full whitespace-pre-line">{issuedBy}</p>

              <p className="w-full whitespace-pre-line">
                {issueDate} - {expiryDate}
              </p>
            </div>
          </div>
        );
      })}
    </>
  );
};

export default certificates;
