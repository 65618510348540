import React from "react";
import {
  FaBehance,
  FaDribbble,
  FaFacebook,
  FaGithub,
  FaGoodreadsG,
  FaMediumM,
  FaInstagram,
  FaLinkedinIn,
  FaProductHunt,
  FaTwitter,
  FaYoutube,
  FaXing,
  FaPhone,
  FaEnvelope,
  FaWhatsapp,
} from "react-icons/fa";
import { SiStackoverflow } from "react-icons/si";
import { SocialType } from "../../types";

const HashnodeSvg = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 337 337" fill="none">
      <rect x="113" y="113" width="111" height="111" rx="55.5" fill="black" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M23.155 112.598c-30.873 30.874-30.873 80.93 0 111.804l89.443 89.443c30.874 30.873 80.93 30.873 111.804 0l89.443-89.443c30.873-30.874 30.873-80.93 0-111.804l-89.443-89.443c-30.874-30.873-80.93-30.873-111.804 0l-89.443 89.443zm184.476 95.033c21.612-21.611 21.612-56.651 0-78.262-21.611-21.612-56.651-21.612-78.262 0-21.612 21.611-21.612 56.651 0 78.262 21.611 21.612 56.651 21.612 78.262 0z"
        fill="white"
      />
    </svg>
  );
};

const SocialIcon = ({ name, ...params }) => {
  const icons = {
    behance: FaBehance,
    dribbble: FaDribbble,
    facebook: FaFacebook,
    github: FaGithub,
    goodreads: FaGoodreadsG,
    medium: FaMediumM,
    instagram: FaInstagram,
    linkedin: FaLinkedinIn,
    producthunt: FaProductHunt,
    twitter: FaTwitter,
    youtube: FaYoutube,
    xing: FaXing,
    phone: FaPhone,
    mail: FaEnvelope,
    whatsapp: FaWhatsapp,
    stackoverflow: SiStackoverflow,
    hashnode: HashnodeSvg,
  };

  const Icon = icons[name];

  return Icon ? <Icon {...params} /> : null;
};

SocialIcon.propTypes = {
  name: SocialType.name,
};

export default SocialIcon;
