import React from "react";
import { ProfileType } from "../../types";

const ProfileImage = ({ image, name }) => (
  <div className="relative inline-block leading-none">
    <img
      src={image.publicURL}
      alt={name}
      className="rounded-full relative z-10 w-40"
    />
    <div
      className="absolute inset-0 rounded-full bg-lead z-0 transform translate-x-12"
      style={{
        backgroundColor: "rgba(10, 186, 230, 0.4)",
      }}
    ></div>
  </div>
);

ProfileImage.propTypes = {
  image: ProfileType.image,
  name: ProfileType.name,
};

export default ProfileImage;
